<template>
  <div>
    <b-loading
      v-model="loading"
      is-full-page
      class="is-primary"
    ></b-loading>
    <form-view v-if="formViewReady && currentForm"></form-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import api from '@/api';
import FormView from '@/components/form/FormView';
import { getReqDraftDirectorFiles, getReqDraftModel } from '@/utils/helper';

export default {
  name: 'ReqForm',
  components: { FormView },
  data () {
    return {
      loading: false,
      formViewReady: false
    };
  },
  mounted () {
    this.loading = true;
    if (this.draftId) {
      Promise.all([api.getReqFormById(this.draftId), api.getReqAttachmentsByFormId(this.draftId)])
        .then(([reqFormResp, reqAttachmentsResp]) => {
          this.startForm('REQ');
          this.setModel(getReqDraftModel(reqFormResp.data.form));
          this.setDirectorFiles(getReqDraftDirectorFiles(reqAttachmentsResp.data, this.model.reqDirector));
          this.loading = false;
          this.formViewReady = true;
        })
        .catch(() => {
          this.$router.push({ name: 'professionalaccess-req' });
        });
    } else {
      this.startForm('REQ');
      this.loading = false;
      this.formViewReady = true;
    }
  },
  computed: {
    ...mapState('form', [
      'currentForm',
      'model'
    ]),
    ...mapGetters('form', [
      'isFirstStep',
      'isLastStep'
    ]),
    draftId () {
      return this.$route.query.draftId;
    }
  },
  methods: {
    ...mapActions('form', [
      'clearForm',
      'previousStep',
      'setDirectorFiles',
      'setModel',
      'startForm'
    ]),
    confirmFormLeave (next) {
      this.$buefy.dialog.confirm({
        message: this.$t('form.warning.message'),
        cancelText: this.$t('form.warning.cancel'),
        confirmText: this.$t('form.warning.confirm'),
        focusOn: 'cancel',
        onConfirm: () => {
          next();
          this.clearForm();
        },
        onCancel: () => {
          next(false);
        }
      });
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.currentForm || to.query?.quit === 'force') {
      next();
      this.clearForm();
    } else if (to.query?.quit === 'warn' || this.isFirstStep) {
      this.confirmFormLeave(next);
    } else if (!this.isFirstStep) {
      this.previousStep();
      next(false);
    } else {
      next();
    }
  }
};
</script>
